<template>
  <home-base-card
    card_title="X連携管理"
    :link_path="link_path"
    icon="mdi-link-variant"
    :loading="loadingFlg"
    :set_color="postInfo.today > 0 ? 'bg_ok' : 'bg_ng'"
    :is_error="isError"
  >
    <div>
      本日投稿<span class="text-h5 text-blue ml-1 mr-1">{{
        postInfo.today
      }}</span
      >/ {{ postInfo.today_limit }} 回<span class="ml-2"
        >{{ postInfo.thisMonth }}月投稿<span
          class="text-h5 text-blue ml-1 mr-1"
          >{{ postInfo.month }}</span
        >/ {{ postInfo.month_limit }} 回</span
      >
    </div>
  </home-base-card>
</template>
<script>
import { reactive, toRefs, onMounted } from "@vue/composition-api";
import store from "@/store/index.js";
import { repositoryFactory } from "@/repository/repositoryFactory";
import setting from "@/common/setting.js";
import utilDate from "@/common/utilDate.js";
import utilFunc from "@/common/utilFunc.js";
import retryRequest from "@/common/retryRequest";

export default {
  props: ["link_path"],
  setup(props, ctx) {
    // const shopSiteXAccountRepository =
    //   repositoryFactory.get("shopSiteXAccounts");
    const shopSitePostCountRepository =
      repositoryFactory.get("shopSitePostCounts");

    const state = reactive({
      loadingFlg: false,
      siteCount: 0,
      isError: false,
      postInfo: {
        today: 0,
        today_limit: 0,
        month: 0,
        month_limit: 0,
        // account: 0,
        // account_limit: 0,
        thisMonth: 0,
      },
    });

    // 投稿状況を取得する
    const getPostInfo = async () => {
      state.isError = false;
      state.loadingFlg = true;

      try {
        const now = new Date();
        // 投稿状況/契約情報 取得
        const currentShop = store.getters["shops/currentShop"];
        const count = await utilFunc.getValidOptionCount(
          currentShop.data.valid_option,
          setting.plan_code_x_link
        );

        // 今月を取得
        state.postInfo.thisMonth = now.getMonth() + 1;
        if (count > 0) {
          state.postInfo.today_limit = count * setting.x_com_max_post_day;
          state.postInfo.month_limit = count * setting.x_com_max_post_month;
          state.postInfo.account_limit = count * setting.x_com_max_link;
        }

        // 件数取得
        let params = {
          shop_id: currentShop.id,
          today: utilDate.getDateFormatLong(now),
        };
        const responseP = await retryRequest(
          shopSitePostCountRepository.get_all_count,
          params
        );
        if (responseP.data) {
          state.postInfo.today = responseP.data.today;
          state.postInfo.month = responseP.data.month;
        }

        // // X連携のshop_site_idをparamsで渡す
        // const shopSites = store.getters["shop_sites/getShopSiteList"];
        // const xLinkIds = shopSites.filter((x) =>
        //   setting.shop_site_id_only_site_ids.includes(x.site_id)
        // );
        // params = {
        //   shop_id: currentShop.id,
        //   shop_site_ids: xLinkIds.map((x) => x.id),
        // };
        // const responseA = await retryRequest(
        //   shopSiteXAccountRepository.get_account_count,
        //   params
        // );
        // if (responseA.data) {
        //   state.postInfo.account = responseA.data.count;
        // }
      } catch (error) {
        state.isError = true;
      } finally {
        state.loadingFlg = false;
      }
    };

    onMounted(() => {
      getPostInfo();
    });

    return {
      props,
      ...toRefs(state),
    };
  },
};
</script>
