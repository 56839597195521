<template>
  <div>
    <v-card class="ma-4">
      <v-card-text>
        <p class="mb-1">
          【お知らせ】下記の時間帯において、ヤトイテのメンテナンスを実施致します。
        </p>
        <p class="font-weight-bold text-black big text-red mb-1 ml-4">
          2025年02月20日(木) AM3:00 〜 AM6:00
        </p>
        メンテナンス中は全てのサービスが利用できません。更新機能も停止するため、更新が必要な際は、直接広告媒体にログインしての更新作業をお願い致します。大変ご迷惑をお掛けしますが、何卒ご理解の程よろしくお願い申し上げます。
      </v-card-text>
    </v-card>
    <v-card elevation="0" class="pb-4">
      <div :key="refreshKey">
        <v-card-title>
          ヤトイテの設定・更新状況
          <v-spacer></v-spacer>
          <div class="mini">
            {{ utilTime.getTimeFormat(new Date()) }}
            <span
              :class="`${!isReloading ? 'cursor-pointer' : ''}`"
              @click="reload"
            >
              <v-icon
                class="ml-2"
                :color="`${isReloading ? 'gray' : 'blue'}`"
                small
                >mdi-reload</v-icon
              ><span :class="`${!isReloading ? 'text-v-blue' : ''} font-12`"
                >再表示</span
              ></span
            >
          </div>
        </v-card-title>
        <v-card-subtitle
          >現在の設定内容・更新状況を表示しています。クリックすると各画面に移動します。</v-card-subtitle
        >
        <div>
          <v-container fluid>
            <v-row>
              <v-col
                v-for="item in viewCardList"
                :key="item.link"
                cols="12"
                sm="6"
                md="4"
              >
                <component
                  v-bind:is="item.component"
                  :link_path="item.link"
                ></component>
              </v-col>
            </v-row>
            <v-row v-if="showXBanner">
              <v-col col="12">
                <div class="text-center mini">
                  <span>[PR]</span>&nbsp;
                  <a
                    class="font-bold"
                    href="https://yatoite.net/xlink/"
                    target="_blank"
                    >YATOITE新機能！X連携オプション提供開始</a
                  >
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col col="12">
                <result-log-card-component></result-log-card-component>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </v-card>
    <v-card elevation="0" class="pb-4">
      <v-card-title>サポート情報</v-card-title>
      <v-card-subtitle
        >ヤトイテに関するご質問・ご相談は、LINE公式アカウントからお気軽にお問い合わせください。担当者よりトークルームにて返答いたします。</v-card-subtitle
      >
      <v-card-text>
        <div class="d-flex justify-start pt-2 pb-2">
          <div class="text-h5 align-self-center line_account">
            【公式】YATOITE（ヤトイテ）
          </div>
          <img
            src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
            alt="友だち追加"
            height="36"
            class="align-self-center cursor-pointer"
            @click="lineOpen"
          />
        </div>
        <div class="ml-2">
          ※友だち追加をクリックするとQRコードが表示されます。
        </div>
      </v-card-text>
    </v-card>
    <v-card elevation="0" class="">
      <v-card-title>
        <!-- 設定済コンテンツ一覧 -->
        運営からのお知らせ
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="（キーワード検索）"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <!-- テーブル -->
      <v-data-table
        id="info_table"
        :headers="headers"
        :items="desserts"
        :search="search"
        item-key="id"
        :items-per-page="5"
        :mobile-breakpoint="0"
        :footer-props="{
          'items-per-page-options': [5, 10, 50, 100, -1],
          'items-per-page-text': '表示件数',
        }"
      >
        <!-- eslint-disable-next-line -->
        <template v-slot:footer.page-text="props">
          {{ props.pageStart }} ～{{ props.pageStop }}件／全{{
            props.itemsLength
          }}件
        </template>
        <!-- NEW表示 -->
        <!-- eslint-disable-next-line -->
        <template v-slot:item.new="{ item }">
          <div class="new_anime">{{ item.new }}</div>
        </template>
        <template v-slot:no-data> 現在、お知らせはありません </template>
      </v-data-table>
      <div class="text-subtitle-2 mt-2 pa-1 orange--text orange lighten-5">
        【注意事項】<br />通信エラー等で処理が停止した際は、ブラウザの再読み込み、又は、F5キーを押して、ページを再読み込みして下さい。
      </div>
    </v-card>
  </div>
</template>

<script>
import { reactive, toRefs, watch } from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import utilDate from "@/common/utilDate.js";
import utilTime from "@/common/utilTime.js";
import utilFunc from "@/common/utilFunc.js";
import modelShopSite from "@/model/shopSite.js";
import { mapState } from "vuex";
import retryRequest from "@/common/retryRequest";

import castCardComponent from "./component/castCard.vue";
import scheduleCardComponent from "./component/scheduleCard.vue";
import synchroContentsComponent from "./component/synchroContentsCard.vue";
import templateCardComponent from "./component/templateCard.vue";
import workingCardComponent from "./component/workingCard.vue";
import settingSynchroCardComponent from "./component/settingSynchroCard.vue";
import resultLogCardComponent from "./component/resultLogCard.vue";
import xLinkCardComponent from "./component/xLinkCard.vue";

export default {
  computed: {
    ...mapState("home", ["refreshKey"]),
  },
  components: {
    castCardComponent,
    scheduleCardComponent,
    synchroContentsComponent,
    templateCardComponent,
    workingCardComponent,
    settingSynchroCardComponent,
    resultLogCardComponent,
    xLinkCardComponent,
  },
  setup(props, ctx) {
    const informationsRepository = repositoryFactory.get("informations");
    const state = reactive({
      isReloading: false,
      search: "",
      headers: [
        {
          text: "",
          value: "new",
          align: "center",
          width: 30,
          sortable: false,
        },
        {
          text: "日付",
          value: "date",
          align: "center",
          width: 100,
        },
        {
          text: "タイトル",
          value: "title",
          sortable: false,
          width: 250,
        },
        {
          text: "コメント",
          value: "content",
          sortable: false,
          class: "td_content",
        },
      ],
      desserts: [],
      viewCardList: [],
      showXBanner: false,
    });

    const cardList = [
      {
        index: 1,
        component: castCardComponent,
        link: "/cast",
      },
      {
        index: 2,
        component: scheduleCardComponent,
        link: "/schedule",
      },
      {
        index: 3,
        component: synchroContentsComponent,
        link: "/synchro-contents",
      },
      {
        index: 4,
        component: templateCardComponent,
        link: "/template",
      },
      {
        index: 5,
        component: workingCardComponent,
        link: "/working",
      },
      {
        index: 6,
        component: settingSynchroCardComponent,
        link: "/setting-synchro",
      },
    ];

    const setCard = async () => {
      state.viewCardList.splice(0);
      cardList.forEach((card) => {
        state.viewCardList.push(card);
      });
      // 契約情報をみてカードを追加す
      const currentShop = store.getters["shops/currentShop"];
      // 契約チェック(X連携)
      if (
        (await utilFunc.getValidOptionCount(
          currentShop.data.valid_option,
          setting.plan_code_x_link
        )) > 0
      ) {
        state.viewCardList.push({
          index: cardList.length + 1,
          component: xLinkCardComponent,
          link: "/x-link",
        });
        state.showXBanner = false;
      } else {
        state.showXBanner = true;
      }
    };

    // 初期化処理(同期処理)
    const init = async () => {
      // ローディング表示
      store.dispatch("loadingIcon/showIcon");

      // カードを設定する
      await setCard();

      // 更新媒体を取得する
      await modelShopSite.setStore();

      // お知らせ
      const checkDate = new Date();
      checkDate.setDate(checkDate.getDate() - 7);
      state.desserts.splice(0);

      const response = await retryRequest(
        informationsRepository.list,
        null,
        true
      );
      if (response.data) {
        Object.keys(response.data).forEach(function (key) {
          const new_text =
            utilDate.getDateFormatLong(checkDate) < response.data[key].date
              ? "NEW"
              : "";
          state.desserts.push({
            "new": new_text,
            "id": response.data[key].id,
            "date": response.data[key].date,
            "title": response.data[key].title,
            "content": response.data[key].content,
            "type": response.data[key].type,
          });
        });
      }

      // await informationsRepository
      //   .list()
      //   .then((response) => {
      //     if (response.data) {
      //       Object.keys(response.data).forEach(function (key) {
      //         const new_text =
      //           utilDate.getDateFormatLong(checkDate) < response.data[key].date
      //             ? "NEW"
      //             : "";
      //         state.desserts.push({
      //           "new": new_text,
      //           "id": response.data[key].id,
      //           "date": response.data[key].date,
      //           "title": response.data[key].title,
      //           "content": response.data[key].content,
      //           "type": response.data[key].type,
      //         });
      //       });
      //     }
      //   })
      //   .catch((error) => {
      //     throw (
      //       "ERROR:home/index.vue/init informationsRepository.list (" +
      //       error +
      //       ")"
      //     );
      //   });

      // ローディング非表示
      store.dispatch("loadingIcon/hideIcon");
    };

    // 初期化する
    init();

    watch(
      () => store.getters["home/getRefreshKey"],
      (newVal) => {
        setCard();
      }
    );

    const reload = () => {
      if (state.isReloading) return;
      state.isReloading = true;
      store.dispatch("home/refreshHome");
      setTimeout(() => {
        state.isReloading = false;
      }, 10000);
    };

    const lineOpen = () => {
      window.open("https://lin.ee/Q0ishRf", "_blank");
    };

    return {
      ...toRefs(state),
      cardList,
      utilTime,
      reload,
      lineOpen,
    };
  },
};
</script>

<style scoped>
::v-deep #info_table thead {
  background-color: #f6f6f6 !important;
}
::v-deep .v-data-table table tbody tr td {
  padding: 0 6px !important;
}
::v-deep .td_content {
  min-width: 250px;
}
.new_anime {
  color: red;
}
.line_account {
  font-weight: bold !important;
  color: black !important;
}
.bk_support {
  background-color: #eaf6fb;
}
.bk_update,
.bk_info {
  background-color: #f6f6f6;
}
</style>
